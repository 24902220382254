/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { type QueryClient, useQuery } from '@tanstack/react-query';
import { isZoover } from '@vakantiesnl/components/src/utils';
import { createRequestByEntityId } from '@vakantiesnl/services/src/nbc';
import { mapFullAccommodation } from '@vakantiesnl/services/src/search/vaknl-mapper';
import { getMinutesInMs } from '@vakantiesnl/services/src/util';
import { StatusCodeError } from '@vakantiesnl/services/src/util/errorHandling';
import { fetchHandler } from '@vakantiesnl/services/src/util/fetchHandler';
import { fetchVakNL } from '@vakantiesnl/services/src/util/vaknl-fetch';
import { type Nbc, type Search } from '@vakantiesnl/types';
import { useRouter } from 'next/router';

import { replaceUndefinedByNull } from '../../util/replaceUndefinedByNull';

function assertDefined(data?: Search.FullAccommodationItem): asserts data is Search.FullAccommodationItem {
	if (!data) {
		throw new TypeError('Error in useGetAccommodationByEntityId: no acco data found');
	}
}

export function useGetAccommodationByEntityId() {
	const router = useRouter();

	const entityId = router.query.entityId?.toString() || ''; // todo: check validity of id (amount of digits, no letters etc)

	// Only fetch accommodation when on the acco page or when another API is used
	const isAccoPage = router.pathname === '/accommodation';

	const query = useQuery({
		queryKey: [entityId],
		staleTime: getMinutesInMs(15),
		queryFn: () => fetchAccommodationByEntityId(entityId).then(replaceUndefinedByNull),
		enabled: isAccoPage && !!entityId,
	});

	// For now we can assume we have the data, as there are no loading states yet fetching the acco details
	// We can assume the data is here as it is already fetched in the getInitialProps of this page
	assertDefined(query.data);

	return {
		accommodation: query.data,
	};
}

export async function getAccommodationByEntityId(
	queryClient: QueryClient,
	entityId: string,
): Promise<Search.FullAccommodationItem> {
	return replaceUndefinedByNull(
		await queryClient.fetchQuery({
			queryKey: [entityId],
			queryFn: () => fetchAccommodationByEntityId(entityId).then(replaceUndefinedByNull),
			staleTime: getMinutesInMs(15),
		}),
	);
}

async function fetchAccommodationByEntityId(entityId: string): Promise<Search.FullAccommodationItem> {
	const request = createRequestByEntityId(entityId, isZoover);

	const nbc = await fetchHandler<Nbc.RawFullNbcItem>({
		fetchFn: () => fetchVakNL({ input: request }),
		errorMessage: 'Error fetching accommodation by path',
	});

	if (!nbc.accommodation) {
		throw new StatusCodeError(410);
	}

	return mapFullAccommodation({
		name: nbc.accommodation?.name ?? '',
		giata_id: nbc.accommodation?.giata_id ?? 0,
		nbc,
	});
}
