import { useEffect, useMemo } from 'react';

import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { hasEnabledFeature } from '@vakantiesnl/components/src/atoms/FeatureToggle/utils';
import { useTripFilterContext } from '@vakantiesnl/services/src/context/useTripFilterContext';
import { useGetAccommodationByEntityId } from '@vakantiesnl/services/src/hooks/queries/useGetAccommodationByEntityId';
import { type OfferResponse } from '@vakantiesnl/services/src/search';
import { mapCompactAccommodations } from '@vakantiesnl/services/src/search/vaknl-mapper';
import { useFeatures, usePartyComposition } from '@vakantiesnl/services/src/stores';
import { isPackageOffer } from '@vakantiesnl/services/src/util';
import { isNotEmpty } from '@vakantiesnl/services/src/util/arrayUtils';
import {
	getDateWithoutTime,
	getDateYMD,
	getMaxDepartureDate,
	getMinDepartureDate,
} from '@vakantiesnl/services/src/util/dateUtils';
import { errorHandler, parseErrorToReport } from '@vakantiesnl/services/src/util/errorHandling';
import { fetchHandler } from '@vakantiesnl/services/src/util/fetchHandler';
import { getDefaultDate } from '@vakantiesnl/services/src/util/getDefaultDate';
import { fetchVakNL } from '@vakantiesnl/services/src/util/vaknl-fetch';
import type { Search } from '@vakantiesnl/types/src';
import { type OfferItem, type RawParty } from '@vakantiesnl/types/src/search';

import type { DepartureDateOffersRequestBody } from './types';
import { useAccoOfferStore } from '../../../stores/accommodationStore/useAccoStore';
import { getDepartureDateAndOffset, PAGE_OFFSET } from '../../utils/offerUtils';

type Filters = {
	party: Search.PartyComposition[];
	durations: number[];
	airports: string[];
	mealplans: string[];
	departureDate: string | undefined;
	hasUserSelectedDate: boolean; // Did the user manually select the departure date or not
};

export function useGetOfferFilters(): Filters {
	const {
		state,
		computed: { durations },
	} = useTripFilterContext();
	const party = usePartyComposition();

	return useMemo(() => {
		return {
			party,
			durations: durations.map((value) => value - 1),
			airports: state.airports || [],
			mealplans: state.mealplans || [],
			departureDate: state.departureDate?.[0] || getDefaultDate(),
			hasUserSelectedDate: !!state.departureDate?.length,
		};
	}, [state.departureDate, durations, party, state.airports, state.mealplans]);
}

export type GetDepartureDateOffersQuery = ReturnType<typeof useGetDepartureDatePackageOffers>;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useGetDepartureDatePackageOffers() {
	const { state } = useTripFilterContext();
	const features = useFeatures();
	const isInfiniteOffersEnabled = hasEnabledFeature(features, 'infiniteOffers');

	const { accommodation } = useGetAccommodationByEntityId();
	const giataId = accommodation.giataId;

	const filters = useGetOfferFilters();

	const isDepartureDateLoading = useAccoOfferStore((s) => s.isDepartureDateLoading);
	const setIsDepartureDateLoading = useAccoOfferStore((s) => s.setIsDepartureDateLoading);
	const setTotalDepartureDateOffers = useAccoOfferStore((s) => s.setTotalDepartureDateOffers);

	const dateToStartFrom = state.selected.date || filters.departureDate;

	const query = useQuery({
		queryKey: ['departureDate', giataId, JSON.stringify(filters)],
		queryFn: () =>
			fetchDepartureDateOffers(
				giataId,
				filters,
				filters.departureDate
					? getDateYMD(getDateWithoutTime(filters.departureDate).subtract(15, 'day'))
					: undefined,
			),
		enabled: accommodation.isBookable && !isInfiniteOffersEnabled,
	});

	const infiniteQuery = useInfiniteQuery({
		queryKey: ['departureDateInfiniteScroll', giataId, JSON.stringify(filters)],
		queryFn: ({ pageParam }) => fetchDepartureDateOffers(giataId, filters, pageParam),
		enabled: accommodation.isBookable && isInfiniteOffersEnabled,
		getNextPageParam: (_lastPage, _allPages, lastStartDate) => {
			const maxDepartureDate = getMaxDepartureDate();

			if (!lastStartDate || getDateWithoutTime(lastStartDate).isSameOrAfter(maxDepartureDate)) {
				return undefined;
			}

			const nextStartDate = getDateWithoutTime(lastStartDate).add(PAGE_OFFSET, 'day');

			return getDateYMD(nextStartDate);
		},
		getPreviousPageParam: (_lastPage, _allPages, lastStartDate) => {
			const minDepartureDate = getMinDepartureDate();

			if (!lastStartDate || getDateWithoutTime(lastStartDate).isSameOrBefore(minDepartureDate)) {
				return undefined;
			}

			const nextStartDate = getDateWithoutTime(lastStartDate).subtract(PAGE_OFFSET, 'day');

			return getDateYMD(nextStartDate);
		},
		initialPageParam: dateToStartFrom
			? getDateYMD(getDateWithoutTime(dateToStartFrom).subtract(15, 'day'))
			: undefined,
	});

	const mergedOffers = useMemo(() => {
		return infiniteQuery.data?.pages ? getMergedDepartureDateOffers(infiniteQuery.data.pages) : undefined;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [infiniteQuery.data?.pages]);

	useEffect(() => {
		const total = isInfiniteOffersEnabled ? mergedOffers?.all.length : query.data?.all.length;
		setTotalDepartureDateOffers(total || 0);
	}, [isInfiniteOffersEnabled, mergedOffers, query.data, setTotalDepartureDateOffers]);

	useEffect(() => {
		if (query.isLoading !== isDepartureDateLoading && !isInfiniteOffersEnabled) {
			setIsDepartureDateLoading(query.isLoading);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query.isLoading, isInfiniteOffersEnabled]);

	useEffect(() => {
		if (infiniteQuery.isLoading !== isDepartureDateLoading && isInfiniteOffersEnabled) {
			setIsDepartureDateLoading(infiniteQuery.isLoading);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [infiniteQuery.isLoading, isInfiniteOffersEnabled]);

	return {
		departureDateOffers: isInfiniteOffersEnabled ? mergedOffers : query.data,
		fetchNextDepartureDateOffers: infiniteQuery.fetchNextPage,
		fetchPrevDepartureDateOffers: infiniteQuery.fetchPreviousPage,
		hasNextDepartureDateOffers: infiniteQuery.hasNextPage,
		hasPrevDepartureDateOffers: infiniteQuery.hasPreviousPage,
		isFetchingDepartureDateOffers: infiniteQuery.isFetching,
	};
}

function getMergedDepartureDateOffers(data: (DepartureDateOffersResponse | undefined)[]): DepartureDateOffersResponse {
	const allOffers = data.flatMap((d) => d?.all).filter(isNotEmpty);

	const min = Math.min(...data.flatMap((d) => d?.min).filter(isNotEmpty));
	const max = Math.max(...data.flatMap((d) => d?.max).filter(isNotEmpty));
	const party = data.flatMap((d) => d?.party).filter(isNotEmpty)[0];
	const pricesPerAdult = allOffers.map(({ pricePerAdult }) => pricePerAdult);
	const cheapestPricePerAdult = Math.min(...pricesPerAdult);
	const cheapest = allOffers.find((o) => min === o.priceTotal);

	return {
		min,
		max,
		cheapest: cheapest?.id || '',
		cheapestPricePerAdult,
		all: allOffers,
		party,
	};
}

export type DepartureDateOffersResponse = {
	min: number;
	max: number;
	cheapest: string;
	cheapestPricePerAdult: number;
	all: OfferItem[];
	party: RawParty;
};

async function fetchDepartureDateOffers(
	giataId: number | undefined,
	filters: Filters,
	nextDepartureDate: string | undefined,
): Promise<DepartureDateOffersResponse | undefined> {
	if (!giataId) {
		return undefined;
	}

	const { departureDate, preOffset, postOffset } = getDepartureDateAndOffset(nextDepartureDate);

	const requestBody: DepartureDateOffersRequestBody = {
		brand: 'ZVRNL',
		party: filters.party[0],
		rooms: filters.party,
		page_size: 100,
		variant: 'date',
		pre_offset: preOffset,
		post_offset: postOffset,
		departure_date: departureDate,
		filters: {
			...(filters.durations.length && { durations_flight: filters.durations }),
			...(filters.airports.length && { departure_airports: filters.airports }),
			...(filters.mealplans.length && { mealplans: filters.mealplans }),
		},
	};

	try {
		const result = await fetchHandler<OfferResponse>({
			fetchFn: () =>
				fetchVakNL({
					input: new Request(
						`${process.env.NEXT_PUBLIC_SEARCH_ENDPOINT_URL}/api/v1/accommodation/${giataId}/offers?check_availability=true`,
						{
							method: 'POST',
							headers: {
								'Content-Type': 'application/json',
							},
							body: JSON.stringify(requestBody),
						},
					),
				}),
			errorMessage: 'Cannot fetch departure date offers',
		});

		const accommodations = mapCompactAccommodations(result.accommodations);
		const allOffers = accommodations.length ? accommodations[0].offers.filter(isPackageOffer) : [];

		const prices = allOffers.map(({ priceTotal }) => priceTotal);
		const pricesPerAdult = allOffers.map(({ pricePerAdult }) => pricePerAdult);
		const [min, max] = [Math.min(...prices), Math.max(...prices)];
		const cheapestPricePerAdult = Math.min(...pricesPerAdult);
		const cheapest = allOffers.find(({ priceTotal }) => min === priceTotal);

		return {
			min,
			max,
			party: result.party,
			cheapest: cheapest?.id || '',
			cheapestPricePerAdult,
			all: allOffers,
		};
	} catch (e) {
		errorHandler.report(parseErrorToReport(e, `Error in fetchDepartureDateOffers`));
		throw new Error('Error in fetchDepartureDateOffers' + e);
	}
}
