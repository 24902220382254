import { useEffect, useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';
import { useTripFilterContext } from '@vakantiesnl/services/src/context/useTripFilterContext';
import { type OfferResponse, useCreateMealPlanOffersRequest } from '@vakantiesnl/services/src/search';
import { mapCompactAccommodations } from '@vakantiesnl/services/src/search/vaknl-mapper';
import { isPackageOffer } from '@vakantiesnl/services/src/util';
import { errorHandler, parseErrorToReport } from '@vakantiesnl/services/src/util/errorHandling';
import { fetchHandler } from '@vakantiesnl/services/src/util/fetchHandler';
import { fetchVakNL } from '@vakantiesnl/services/src/util/vaknl-fetch';
import { type OfferItem, type RawParty } from '@vakantiesnl/types/src/search';

import { getSelectedOfferByMealplan } from './selectHelpers';
import { useAccoOfferStore } from '../../../stores/accommodationStore/useAccoStore';
import { useGetAccommodationByEntityId } from '../useGetAccommodationByEntityId';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export function useGetMealplanOffers() {
	const roomTypeOffer = useAccoOfferStore((s) => s.roomTypeOffer);
	const mealplanOffer = useAccoOfferStore((s) => s.mealplanOffer);
	const setMealplanOffer = useAccoOfferStore((s) => s.setMealplanOffer);
	const giataId = useGetAccommodationByEntityId().accommodation.giataId;
	const { state } = useTripFilterContext();

	const mealplanRequest = useCreateMealPlanOffersRequest(roomTypeOffer);

	const query = useQuery({
		queryKey: ['mealplan', roomTypeOffer?.id],
		queryFn: () => fetchMealplanOffers(giataId, mealplanRequest, roomTypeOffer),
		enabled: !!roomTypeOffer,
	});

	const selected = useMemo(() => {
		if (!query.data?.all) {
			return undefined;
		}

		return getSelectedOfferByMealplan(state.selected.mealPlan, query.data?.all);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query.data?.all]);

	// Update the store with the selected mealplan offer
	// Compare against previous value to prevent multiple executions in case this hook is called multiple times
	useEffect(() => {
		if (selected?.id !== mealplanOffer?.id) {
			setMealplanOffer(selected);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selected?.id]);

	return {
		isLoadingMealplanOffers: query.isLoading,
		mealplanOffers: query.data,
	};
}

type MealplanOffersResponse = {
	all: OfferItem[];
	party: RawParty;
};

async function fetchMealplanOffers(
	giataId: number | undefined,
	requestBody: string,
	offer: OfferItem | undefined,
): Promise<MealplanOffersResponse | undefined> {
	if (!requestBody || !offer || !giataId) {
		return undefined;
	}

	try {
		const result = await fetchHandler<OfferResponse>({
			fetchFn: () =>
				fetchVakNL({
					input: new Request(
						`${process.env.NEXT_PUBLIC_SEARCH_ENDPOINT_URL}/api/v1/accommodation/${giataId}/offers?check_availability=true`,
						{
							method: 'POST',
							headers: {
								'Content-Type': 'application/json',
							},
							body: requestBody,
						},
					),
				}),
			errorMessage: 'Cannot fetch mealplan offers',
		});

		const accommodations = mapCompactAccommodations(result.accommodations);
		const allOffers = accommodations?.length ? accommodations[0].offers.filter(isPackageOffer) : [];

		return {
			all: allOffers,
			party: result.party,
		};
	} catch (e) {
		errorHandler.report(parseErrorToReport(e, `Error in fetchMealplanOffers`));
		throw new Error('Error in fetchMealplanOffers' + e);
	}
}
