/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { type QueryClient, keepPreviousData, useQuery } from '@tanstack/react-query';
import { isZoover } from '@vakantiesnl/components/src/utils';
import { errorHandler, parseErrorToReport } from '@vakantiesnl/services/src/util/errorHandling';
import { fetchVakNL } from '@vakantiesnl/services/src/util/vaknl-fetch';
import { type Adlink, type AdlinksParams } from '@vakantiesnl/types/src/adlinks';
import { omit } from 'lodash';

import { createAdlinkRequest } from '../../adlinks/adlinks';

function getAdlinksQueryKey({ id }: AdlinksParams) {
	return ['adlinks', id];
}

export function useGetAdlinks(params: AdlinksParams, isDisabled?: boolean) {
	const query = useQuery({
		queryKey: getAdlinksQueryKey(params),
		enabled: !isDisabled && !!params.id && isZoover,
		queryFn: () => fetchAdlinks(params),
		placeholderData: keepPreviousData,
	});

	return {
		adlinks: query.data,
	};
}

export async function prefetchAdlinks(queryClient: QueryClient, params: AdlinksParams) {
	try {
		await queryClient.prefetchQuery({
			queryKey: getAdlinksQueryKey(params),
			queryFn: () => fetchAdlinks(params),
		});
	} catch {
		// Don't throw an error if this crashes since it enhances the page and it's not the main content of the page
	}
}

async function fetchAdlinks(params: AdlinksParams): Promise<Adlink[]> {
	const query = createAdlinkRequest(params);

	try {
		const response = await fetchVakNL({ input: query });
		const adlinks: Adlink[] = await response.json();

		return adlinks.map((adlink) => omit(adlink, 'cpc'));
	} catch (e) {
		errorHandler.report(
			parseErrorToReport(e, `Error fetching adlinks results with data: ${JSON.stringify(params)}`),
		);

		throw new Error('Cannot fetch adlinks results');
	}
}
